import React from 'react';
import './style.css';
import MainNav from './MainNav';

const UnderConstruction = () => {
    return (
      <div className="under-construction"> 
      <MainNav />
      </div>
      
    );
  };
  
  export default UnderConstruction;
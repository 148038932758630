import React from 'react';
import './style.css';

const Music = () => {
  return (
    <div>
        "empty"
    </div>
    
  );
};

export default Music;
